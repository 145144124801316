<template>
  <div ref="autocompleteContainer"></div>
</template>

<script>
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import { createWidgetMixin } from 'vue-instantsearch/vue2/es';
import { INSTANT_SEARCH_INDEX_NAME, INSTANT_SEARCH_QUERY_SUGGESTIONS } from '../constants';
import { searchClient, typesenseClient } from '../searchClient';
import { icons } from '../icons';
import SearchNoResults from './SearchNoResults.vue';

function debounce (fn, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export default {
  mixins: [createWidgetMixin({ connector: connectSearchBox })],
  data () {
    return {
      autocompleteInstance: null,
    };
  },
  methods: {
    setInstantSearchUiState ({ query, menu, toggle, range }) {
      this.instantSearchInstance.setUiState((uiState) => {
        const { ...facets } = { ...uiState[INSTANT_SEARCH_INDEX_NAME] };

        if (menu) facets.menu = menu;
        if (toggle) facets.toggle = { ...facets.toggle, ...toggle };
        if (range) facets.range = { ...facets.range, ...range };

        return ({
          ...uiState,
          [INSTANT_SEARCH_INDEX_NAME]: {
            ...uiState[INSTANT_SEARCH_INDEX_NAME],
            ...facets,
            page: 1,
            query,
          },
        })
      });
    },
  },
  mounted () {
    const {
      instantSearchInstance,
      setInstantSearchUiState,
    } = this;

    const debouncedInstantSearchUiState = debounce(setInstantSearchUiState, 300);

    // Create the Autocomplete instance
    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
      key: 'recentSearchesPlugin',
      limit: 3,
      transformSource ({ source }) {
        return {
          ...source,
          onSelect ({ item }) {
            setInstantSearchUiState({ query: item.label });
          },
        };
      },
    });

    // Create the Query Suggestions plugin
    const querySuggestionsPlugin = createQuerySuggestionsPlugin({
      searchClient,
      indexName: INSTANT_SEARCH_QUERY_SUGGESTIONS,
      getSearchParams ({ state }) {
        // This creates a shared `hitsPerPage` value once the duplicates
        // between recent searches and Query Suggestions are removed
        return recentSearchesPlugin.data.getAlgoliaSearchParams({
          query: state.query,
          hitsPerPage: 6,
        });
      },
      transformSource ({ source }) {
        return {
          ...source,
          sourceId: 'querySuggestionsPlugin',
          onSelect ({ item }) {
            setInstantSearchUiState({ query: item.query });
          },
          getItems (params) {
            // We don't display Query Suggestions when there's no query
            if (!params.state.query) {
              return [];
            }
            const { transformResponse, ...result } = source.getItems(params);
            result.transformResponse = (response) => {
              return transformResponse(response).map(({ q, count }) => ({
                query: q,
                popularity: count
              }));
            };

            return result;
          },
        };
      },
    });

    const initialState = instantSearchInstance.mainIndex.getHelper()?.state || {};
    const queryParams = new URLSearchParams(window.location.search);

    this.autocompleteInstance = autocomplete({
      container: this.$refs.autocompleteContainer,
      placeholder: 'Search for products',
      detachedMediaQuery: 'none',
      initialState: { query: initialState.query || queryParams.get('query') || '' },
      openOnFocus: true,
      plugins: [recentSearchesPlugin, querySuggestionsPlugin],
      components: {
        SearchNoResults,
      },
      async getSources ({ query, state }) {
        const typesenseFacets = await typesenseClient.collections(INSTANT_SEARCH_INDEX_NAME).documents().search({
          q: query,
          query_by: "name,system,type,genre,category.lvl1,category.lvl2,category.lvl3,category.lvl4,category.lvl5,category.lvl6,system_manufacturer,manufacturer,eans",
          facet_by: ['category.lvl1', 'category.lvl2', 'system_manufacturer'],
          max_facet_values: 5,
          limit: 1
        });

        return [
          {
            sourceId: 'facetTagSystem',
            templates: {
              header ({ html }) {
                return html`<div>
                  <span class="aa-SourceHeaderTitle">Kategorie</span>
                  <div class="aa-SourceHeaderLine"></div>
                </div>`;
              },
              item ({ item, html }) {
                return html`<div>${item.value}</div>`;
              },
              noResults ({ html }) {
                return html`<svg
                    width="36.055744"
                    height="27.044922"
                    viewBox="0 0 41.02895 23.766749"
                    style="fill:#888"
                  >
                      <path
                        d="m 28.209445,-3.5035917 v 2.56478984 h 2.56479 2.56479 2.560345 V -3.5035917 h -2.560345 -2.56479 z m 7.689925,2.56478984 V 1.625988 h 2.56479 V -0.93880186 Z M 38.46416,1.625988 v 2.5603448 2.5647899 2.5647899 h 2.56479 V 6.7511227 4.1863328 1.625988 Z m 0,7.6899246 h -2.56479 v 2.5625674 2.56479 h 2.56479 V 11.87848 Z M 35.89937,14.44327 h -2.560345 v 2.56479 h 2.560345 z m -2.560345,2.56479 h -2.56479 v 2.564789 h 2.56479 z m -2.56479,2.564789 h -5.129579 v 2.562568 2.562567 h 2.564789 v -2.562567 h 2.56479 z M 28.209445,-0.93880186 H 25.646878 V 1.625988 h 2.562567 z M 25.646878,1.625988 h -2.56479 v 2.5603448 h 2.56479 z m -5.12958,2.5625674 v 2.5625673 h 2.56479 V 4.1885554 Z m 2.56479,2.5625673 v 2.5625674 h 2.56479 V 6.7511227 Z m 2.56479,2.5625674 v 2.5603449 h 2.56479 V 9.3136901 Z m 0,2.5603449 h -2.56479 v 2.562567 h 2.56479 z m -2.56479,2.562567 h -2.56479 v 2.562567 h 2.56479 z m 0,2.562567 v 2.562568 h 2.56479 v -2.562568 z"
                      />
                      <path
                        d="m 5.1295798,-3.4969241 v 2.56478981 h 2.5647898 2.5625674 2.56479 V -3.4969241 h -2.56479 -2.5625674 z m 7.6921472,2.56478981 V 1.6326556 h 2.56479 v -2.56478989 z m 2.56479,2.56478989 v 2.5558998 h -2.56479 v 2.5625673 h 2.56479 V 4.1930004 h 2.56479 V 1.6326556 Z m 0,5.1184671 v 2.5625674 h 2.56479 V 6.7511227 Z m 2.56479,2.5625674 v 2.5603449 h 2.56479 V 9.3136901 Z m 0,2.5603449 h -2.56479 v 2.562567 h 2.56479 z m -2.56479,2.562567 h -2.56479 v 2.562567 h 2.56479 z m 0,2.562567 v 2.562568 h 2.56479 V 16.999169 Z M 5.1295798,-0.93213429 H 2.5647899 V 1.6326556 H 5.1295798 Z M 2.5647899,1.6326556 H 0 V 4.1930004 6.7577903 9.3225801 H 2.5647899 V 6.7577903 4.1930004 Z m 0,7.6899245 v 2.5625669 2.56479 h 2.5647899 v -2.56479 -2.5625669 z m 2.5647899,5.1273569 v 2.56479 h 2.5647898 v -2.56479 z m 2.5647898,2.56479 v 2.56479 h 2.5625674 v -2.56479 z m 2.5625674,2.56479 v 2.562567 h 2.56479 v -2.562567 z m 2.56479,2.562567 v 2.56479 h 2.56479 v -2.56479 z m 2.56479,2.56479 v 2.56479 h 2.56479 v -2.56479 z m 2.56479,0 h 2.562567 v -2.56479 -2.567012 h -2.56479 l 0.0022,2.567012 z"
                      />
                  </svg>`;
              },
            },
            getItems () {
              const [lvl1, lvl2] = typesenseFacets.facet_counts

              return [...lvl1.counts, ...lvl2.counts]
                .sort((a, b) => b.count - a.count)
                .slice(0, 5)
                .sort((a, b) => a.value.localeCompare(b.value));
            },
          },
          {
            sourceId: 'facetTagSystemManufacturer',
            templates: {
              header ({ html }) {
                return html`<div>
                  <span class="aa-SourceHeaderTitle">Marken</span>
                  <div class="aa-SourceHeaderLine"></div>
                </div>`;
              },
              item ({ item, html }) {
                const { icon, color = "#000000", textColor = "#FFFFFF", keepText } = icons.system_manufacturer[item.value] ?? {};
                const style = `
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  height: 1.75rem;
                  padding: .3rem 0.5rem;
                  gap: .5rem;
                  color: ${textColor};
                  background-color: ${color};
                `;

                return html`<div style="${style}" title="${item.value}" >
                  <img src="${icon}" style="object-fit: contain; width: 100%; height: 100%" />
                    ${keepText ? item.value : ''}
                  </div>`;
              },
              noResults ({ html }) {
                return html`<svg
                    width="36.055744"
                    height="27.044922"
                    viewBox="0 0 41.02895 23.766749"
                    style="fill:#888"
                  >
                      <path
                        d="m 28.209445,-3.5035917 v 2.56478984 h 2.56479 2.56479 2.560345 V -3.5035917 h -2.560345 -2.56479 z m 7.689925,2.56478984 V 1.625988 h 2.56479 V -0.93880186 Z M 38.46416,1.625988 v 2.5603448 2.5647899 2.5647899 h 2.56479 V 6.7511227 4.1863328 1.625988 Z m 0,7.6899246 h -2.56479 v 2.5625674 2.56479 h 2.56479 V 11.87848 Z M 35.89937,14.44327 h -2.560345 v 2.56479 h 2.560345 z m -2.560345,2.56479 h -2.56479 v 2.564789 h 2.56479 z m -2.56479,2.564789 h -5.129579 v 2.562568 2.562567 h 2.564789 v -2.562567 h 2.56479 z M 28.209445,-0.93880186 H 25.646878 V 1.625988 h 2.562567 z M 25.646878,1.625988 h -2.56479 v 2.5603448 h 2.56479 z m -5.12958,2.5625674 v 2.5625673 h 2.56479 V 4.1885554 Z m 2.56479,2.5625673 v 2.5625674 h 2.56479 V 6.7511227 Z m 2.56479,2.5625674 v 2.5603449 h 2.56479 V 9.3136901 Z m 0,2.5603449 h -2.56479 v 2.562567 h 2.56479 z m -2.56479,2.562567 h -2.56479 v 2.562567 h 2.56479 z m 0,2.562567 v 2.562568 h 2.56479 v -2.562568 z"
                      />
                      <path
                        d="m 5.1295798,-3.4969241 v 2.56478981 h 2.5647898 2.5625674 2.56479 V -3.4969241 h -2.56479 -2.5625674 z m 7.6921472,2.56478981 V 1.6326556 h 2.56479 v -2.56478989 z m 2.56479,2.56478989 v 2.5558998 h -2.56479 v 2.5625673 h 2.56479 V 4.1930004 h 2.56479 V 1.6326556 Z m 0,5.1184671 v 2.5625674 h 2.56479 V 6.7511227 Z m 2.56479,2.5625674 v 2.5603449 h 2.56479 V 9.3136901 Z m 0,2.5603449 h -2.56479 v 2.562567 h 2.56479 z m -2.56479,2.562567 h -2.56479 v 2.562567 h 2.56479 z m 0,2.562567 v 2.562568 h 2.56479 V 16.999169 Z M 5.1295798,-0.93213429 H 2.5647899 V 1.6326556 H 5.1295798 Z M 2.5647899,1.6326556 H 0 V 4.1930004 6.7577903 9.3225801 H 2.5647899 V 6.7577903 4.1930004 Z m 0,7.6899245 v 2.5625669 2.56479 h 2.5647899 v -2.56479 -2.5625669 z m 2.5647899,5.1273569 v 2.56479 h 2.5647898 v -2.56479 z m 2.5647898,2.56479 v 2.56479 h 2.5625674 v -2.56479 z m 2.5625674,2.56479 v 2.562567 h 2.56479 v -2.562567 z m 2.56479,2.562567 v 2.56479 h 2.56479 v -2.56479 z m 2.56479,2.56479 v 2.56479 h 2.56479 v -2.56479 z m 2.56479,0 h 2.562567 v -2.56479 -2.567012 h -2.56479 l 0.0022,2.567012 z"
                      />
                  </svg>`;
              },
            },
            getItems () {
              return typesenseFacets.facet_counts[2].counts
            },
          },
          {
            sourceId: 'products',
            templates: {
              header ({ html }) {
                return html`<div>
                  <span class="aa-SourceHeaderTitle">Produkte</span>
                  <div class="aa-SourceHeaderLine"></div>
                </div>`;
              },
              item ({ item, html, components }) {
                return html`<div style="display: flex; gap: 1rem; align-items: center;">
                  <img src="${item.cover_thumbnail}" width="72px" height="72px" style="object-fit: contain;" />
                  <div style="flex-grow: 1;">
                    <b style="display: block; margin-bottom: 0.5rem">
                      ${components.Highlight({ hit: item, attribute: 'name', tagName: 'em' })}
                    </b>
                    <div>
                      ${item.availability}
                    </div>
                    <div>
                      ab ${item.min_price.toFixed(2).replace('.', ',')} €
                    </div>
                  </div>
                </div>`;
              },
              noResults ({ html }) {
                return html`<svg
                    width="36.055744"
                    height="27.044922"
                    viewBox="0 0 41.02895 23.766749"
                    style="fill:#888"
                  >
                      <path
                        d="m 28.209445,-3.5035917 v 2.56478984 h 2.56479 2.56479 2.560345 V -3.5035917 h -2.560345 -2.56479 z m 7.689925,2.56478984 V 1.625988 h 2.56479 V -0.93880186 Z M 38.46416,1.625988 v 2.5603448 2.5647899 2.5647899 h 2.56479 V 6.7511227 4.1863328 1.625988 Z m 0,7.6899246 h -2.56479 v 2.5625674 2.56479 h 2.56479 V 11.87848 Z M 35.89937,14.44327 h -2.560345 v 2.56479 h 2.560345 z m -2.560345,2.56479 h -2.56479 v 2.564789 h 2.56479 z m -2.56479,2.564789 h -5.129579 v 2.562568 2.562567 h 2.564789 v -2.562567 h 2.56479 z M 28.209445,-0.93880186 H 25.646878 V 1.625988 h 2.562567 z M 25.646878,1.625988 h -2.56479 v 2.5603448 h 2.56479 z m -5.12958,2.5625674 v 2.5625673 h 2.56479 V 4.1885554 Z m 2.56479,2.5625673 v 2.5625674 h 2.56479 V 6.7511227 Z m 2.56479,2.5625674 v 2.5603449 h 2.56479 V 9.3136901 Z m 0,2.5603449 h -2.56479 v 2.562567 h 2.56479 z m -2.56479,2.562567 h -2.56479 v 2.562567 h 2.56479 z m 0,2.562567 v 2.562568 h 2.56479 v -2.562568 z"
                      />
                      <path
                        d="m 5.1295798,-3.4969241 v 2.56478981 h 2.5647898 2.5625674 2.56479 V -3.4969241 h -2.56479 -2.5625674 z m 7.6921472,2.56478981 V 1.6326556 h 2.56479 v -2.56478989 z m 2.56479,2.56478989 v 2.5558998 h -2.56479 v 2.5625673 h 2.56479 V 4.1930004 h 2.56479 V 1.6326556 Z m 0,5.1184671 v 2.5625674 h 2.56479 V 6.7511227 Z m 2.56479,2.5625674 v 2.5603449 h 2.56479 V 9.3136901 Z m 0,2.5603449 h -2.56479 v 2.562567 h 2.56479 z m -2.56479,2.562567 h -2.56479 v 2.562567 h 2.56479 z m 0,2.562567 v 2.562568 h 2.56479 V 16.999169 Z M 5.1295798,-0.93213429 H 2.5647899 V 1.6326556 H 5.1295798 Z M 2.5647899,1.6326556 H 0 V 4.1930004 6.7577903 9.3225801 H 2.5647899 V 6.7577903 4.1930004 Z m 0,7.6899245 v 2.5625669 2.56479 h 2.5647899 v -2.56479 -2.5625669 z m 2.5647899,5.1273569 v 2.56479 h 2.5647898 v -2.56479 z m 2.5647898,2.56479 v 2.56479 h 2.5625674 v -2.56479 z m 2.5625674,2.56479 v 2.562567 h 2.56479 v -2.562567 z m 2.56479,2.562567 v 2.56479 h 2.56479 v -2.56479 z m 2.56479,2.56479 v 2.56479 h 2.56479 v -2.56479 z m 2.56479,0 h 2.562567 v -2.56479 -2.567012 h -2.56479 l 0.0022,2.567012 z"
                      />
                  </svg> Keine Ergebnisse gefunden`;
              },
            },
            getItems () {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: INSTANT_SEARCH_INDEX_NAME,
                    params: { query, hitsPerPage: 5 },
                  },
                ],
              });
            },
          },
          {
            sourceId: 'more',
            templates: {
              item ({ item, html }) {
                return html`<div style="display: flex; justify-content: center; align-items: center; height: 40px;">
                  ${item.value}
                </div>`;
              }
            },
            getItems () {
              return [{
                value: "Mehr..."
              }];
            }
          }
        ]
      },
      render ({ elements, render, html }, root) {
        const {
          recentSearchesPlugin,
          querySuggestionsPlugin,
          facetTagSystemManufacturer,
          facetTagSystem,
          products,
          more,
        } = elements;

        render(
          html`<div class="aa-PanelLayout aa-Panel--scrollable">
                  <div class="aa-PanelSections">
                    <div class="aa-PanelSection--left">
                      ${querySuggestionsPlugin}
                      ${recentSearchesPlugin}
                      ${facetTagSystem}
                      ${facetTagSystemManufacturer}
                    </div>
                    <div class="aa-PanelSection--right">
                      ${products}
                      ${more}
                    </div>
                  </div>
          </div>`,
          root
        );
      },
      onSubmit ({ state }) {
        setInstantSearchUiState({ query: state.query });
      },
      onReset () {
        setInstantSearchUiState({ query: '' });
      },
      onStateChange ({ prevState, state }) {
        if (prevState.query !== state.query) {
          debouncedInstantSearchUiState({ query: state.query });
        }
      },
    });
  },
  beforeDestroy () {
    this.autocompleteInstance?.destroy();
  },
  watch: {
    'instantSearchInstance.renderState': {
      immediate: true,
      handler () {
        const state = this.instantSearchInstance.mainIndex.getHelper()?.state || {};
        this.autocompleteInstance?.setContext({ query: state.query })
      },
    },
  }
};
</script>

<style>
:root {
  --aa-spacing-factor: .5;
}

.aa-PanelLayout {
  padding: 0;
  font-size: smaller;
}

.aa-PanelSections {
  display: flex;
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.aa-PanelSection--left,
.aa-PanelSection--right {
  padding: calc((16* 1* 1px) / 2);
  padding: calc(calc(16* 1* 1px) / 2);
  padding: var(--aa-spacing-half);
}

.aa-PanelSection--left {
  flex: 0 1 320px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: .5rem;
  background-color: #eee;
}

.aa-PanelSection--left .aa-SourceHeaderTitle {
  background-color: #eee;
  padding-right: 0;
}

.aa-PanelSection--right {
  flex: 1;
  padding-left: 0;
}


.aa-Source[data-autocomplete-source-id="facetTagSystem"],
.aa-Source[data-autocomplete-source-id="facetTagSystemManufacturer"] {
  margin: var(--aa-spacing);
}

.aa-Source[data-autocomplete-source-id="tagsPlugin"] .aa-List,
.aa-Source[data-autocomplete-source-id="facetTagSystemManufacturer"] .aa-List {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
}

.aa-Source[data-autocomplete-source-id="facetTagSystemManufacturer"] .aa-List {
  justify-content: stretch;
  gap: .3rem;
}

.aa-Source[data-autocomplete-source-id="tagsPlugin"] .aa-Item {
  background-color: rgba(var(--aa-selected-color-rgb), var(--aa-selected-color-alpha));
  cursor: unset;
}


.aa-Source[data-autocomplete-source-id="facetTagSystemManufacturer"] .aa-Item,
.aa-Source[data-autocomplete-source-id="facetTagSystem"] .aa-Item {
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
  min-height: 0;
}

.aa-Source[data-autocomplete-source-id="facetTagSystemManufacturer"] .aa-Item {
  flex-grow: 1;
}

.aa-Source[data-autocomplete-source-id="facetTagSystem"] .aa-Item {
  line-height: 1.5;
}

.aa-Source[data-autocomplete-source-id="tagsPlugin"] .aa-Item .aa-TagRemoveButton {
  height: 16px;
  cursor: pointer;
}

.aa-Source[data-autocomplete-source-id="tagsPlugin"] .aa-Tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
  padding: 0 .5rem;
}

.aa-Source[data-autocomplete-source-id="facetTagSystemManufacturer"] .aa-SourceNoResults,
.aa-Source[data-autocomplete-source-id="facetTagSystem"] .aa-SourceNoResults,
.aa-Source[data-autocomplete-source-id="products"] .aa-SourceNoResults {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.aa-Source[data-autocomplete-source-id="querySuggestionsPlugin"]+.aa-Source[data-autocomplete-source-id="recentSearchesPlugin"] {
  margin-top: calc(0px - var(--aa-spacing));
}

@media screen and (max-width: 768px) {
  .aa-PanelSections {
    flex-direction: column;
    gap: 0;
  }

  .aa-PanelSection--left,
  .aa-PanelSection--right {
    padding: calc((16* 1* 1px) / 2);
    padding: calc(calc(16* 1* 1px) / 2);
    padding: var(--aa-spacing-half);
  }

  .aa-PanelSection--left {
    flex: 1;
  }

  .aa-Source[data-autocomplete-source-id="facetTagSystemManufacturer"],
  .aa-Source[data-autocomplete-source-id="facetTagSystem"] {
    display: none;
  }
}
</style>
