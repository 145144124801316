<template>
  <div v-show="canRefine || showNoRefinements" v-click-outside="hide" class="ais-Dropdown">
    <button @click="open = !open" class="ais-Dropdown__button">
      <slot name="header" :has-refinements="canRefine">
        {{ title }}
      </slot>
    </button>
    <div v-show="open" class="ais-Dropdown__content" ref="content" :style="{ minWidth, left: `${offsetLeft}px` }">
      <slot :has-refinements="canRefine"></slot>
    </div>
  </div>
</template>

<script>
import { createPanelProviderMixin } from '../mixins/panel'

export default {
  mixins: [createPanelProviderMixin()],
  props: {
    title: {
      type: String,
      required: true
    },
    defaultOpen: {
      type: Boolean,
      default: false
    },
    showNoRefinements: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: String,
      default: '0'
    },
    windowPadding: {
      type: Number,
      default: 10
    }
  },
  data () {
    return {
      open: this.defaultOpen,
      offsetLeft: 0,
      resizeObserver: null
    }
  },
  methods: {
    handleResize () {
      if (this.$refs.content === undefined) return

      const rect = this.$refs.content.getBoundingClientRect()
      const rightDistance = window.innerWidth - (rect.left + rect.width)

      this.offsetLeft = Math.min(0, this.offsetLeft + rightDistance - this.windowPadding)
    },
    hide () {
      this.open = false
    }
  },
  mounted () {
    this.resizeObserver = new ResizeObserver(this.handleResize)
    this.resizeObserver.observe(this.$refs.content)
    window.addEventListener('resize', this.handleResize)
  },
}
</script>

<style lang="css">
.ais-Dropdown {
  position: relative;
}

.ais-Dropdown__button {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  color: #212529;
  cursor: pointer;
  display: flex;
  gap: var(--aa-spacing-half);
  font-size: 14px;
  padding: var(--aa-spacing) calc(var(--aa-spacing) * 2);
  justify-content: center;
}

.ais-Dropdown__button:hover {
  background: #e9ecef;
}

.ais-ToggleRefinement {
  text-wrap: nowrap;
}

.ais-Dropdown__content {
  background: #f8f9fa;
  border: 1px solid #f8f9fa;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  padding: var(--aa-spacing);
  border: 1px solid #dee2e6;
  margin-top: var(--aa-spacing-half);
  z-index: 1;
}
</style>
