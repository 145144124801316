<template>
  <div>
    <svg width="36.055744" height="27.044922" viewBox="0 0 41.02895 23.766749" style="fill:#888">
      <path
        d="m 28.209445,-3.5035917 v 2.56478984 h 2.56479 2.56479 2.560345 V -3.5035917 h -2.560345 -2.56479 z m 7.689925,2.56478984 V 1.625988 h 2.56479 V -0.93880186 Z M 38.46416,1.625988 v 2.5603448 2.5647899 2.5647899 h 2.56479 V 6.7511227 4.1863328 1.625988 Z m 0,7.6899246 h -2.56479 v 2.5625674 2.56479 h 2.56479 V 11.87848 Z M 35.89937,14.44327 h -2.560345 v 2.56479 h 2.560345 z m -2.560345,2.56479 h -2.56479 v 2.564789 h 2.56479 z m -2.56479,2.564789 h -5.129579 v 2.562568 2.562567 h 2.564789 v -2.562567 h 2.56479 z M 28.209445,-0.93880186 H 25.646878 V 1.625988 h 2.562567 z M 25.646878,1.625988 h -2.56479 v 2.5603448 h 2.56479 z m -5.12958,2.5625674 v 2.5625673 h 2.56479 V 4.1885554 Z m 2.56479,2.5625673 v 2.5625674 h 2.56479 V 6.7511227 Z m 2.56479,2.5625674 v 2.5603449 h 2.56479 V 9.3136901 Z m 0,2.5603449 h -2.56479 v 2.562567 h 2.56479 z m -2.56479,2.562567 h -2.56479 v 2.562567 h 2.56479 z m 0,2.562567 v 2.562568 h 2.56479 v -2.562568 z" />
      <path
        d="m 5.1295798,-3.4969241 v 2.56478981 h 2.5647898 2.5625674 2.56479 V -3.4969241 h -2.56479 -2.5625674 z m 7.6921472,2.56478981 V 1.6326556 h 2.56479 v -2.56478989 z m 2.56479,2.56478989 v 2.5558998 h -2.56479 v 2.5625673 h 2.56479 V 4.1930004 h 2.56479 V 1.6326556 Z m 0,5.1184671 v 2.5625674 h 2.56479 V 6.7511227 Z m 2.56479,2.5625674 v 2.5603449 h 2.56479 V 9.3136901 Z m 0,2.5603449 h -2.56479 v 2.562567 h 2.56479 z m -2.56479,2.562567 h -2.56479 v 2.562567 h 2.56479 z m 0,2.562567 v 2.562568 h 2.56479 V 16.999169 Z M 5.1295798,-0.93213429 H 2.5647899 V 1.6326556 H 5.1295798 Z M 2.5647899,1.6326556 H 0 V 4.1930004 6.7577903 9.3225801 H 2.5647899 V 6.7577903 4.1930004 Z m 0,7.6899245 v 2.5625669 2.56479 h 2.5647899 v -2.56479 -2.5625669 z m 2.5647899,5.1273569 v 2.56479 h 2.5647898 v -2.56479 z m 2.5647898,2.56479 v 2.56479 h 2.5625674 v -2.56479 z m 2.5625674,2.56479 v 2.562567 h 2.56479 v -2.562567 z m 2.56479,2.562567 v 2.56479 h 2.56479 v -2.56479 z m 2.56479,2.56479 v 2.56479 h 2.56479 v -2.56479 z m 2.56479,0 h 2.562567 v -2.56479 -2.567012 h -2.56479 l 0.0022,2.567012 z" />
    </svg>
    Keine Ergebnisse gefunden
  </div>
</template>

<script>
export default {
  data () {
    return {};
  },
};
</script>
