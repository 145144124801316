<template>
  <div v-if="state" :class="[suit(), !state.canRefine && suit('', 'noRefinement')]">
    <div class="ais-RangeInput__suggestionList">
      <button v-for="suggestion in quickSuggestions" :key="suggestion.label" class="ais-RangeInput__suggestion"
        @click="state.refine(suggestion.value)" style="cursor: pointer;">
        {{ suggestion.label }}
      </button>
    </div>
    <ais-refinement-list :attribute="attribute" :limit="limit" :transform-items="transformToHistogram"
      :class-names="{ 'ais-RefinementList': 'ais-RefinementList__histogram' }">
      <template #item="{ item }">
        <button class="ais-RefinementList__bucket" :title="item.label"
          @click="state.refine(item.value.split(':').map(parseFloat))">
          <div class="ais-RefinementList__bucketDisplay"
            :style="{ height: `${scaleValue(item.count, maxCount) * 100}%` }"></div>
        </button>
      </template>
    </ais-refinement-list>
    <div class="ais-RangeInput__slider">
      {{ values[0] }}-{{ values[1] }}
      <vue-slider :data="steps" :value="values" :min-range="1"
        @change="$event[0] < $event[1] ? state.refine($event) : undefined" :tooltip="input ? 'active' : 'always'"
        :tooltip-formatter="formatValue" tooltip-placement="bottom" :lazy="true" :contained="true" :adsorb="true"
        style="flex-grow: 1;" />
    </div>
    <div v-if="input" class="ais-RangeInput__values">
      <input type="number" :value="values[0]" @input="state.refine([$event.target.value, values[1]])" :step="step"
        :min="range.min" :max="range.max" />
      <div>bis</div>
      <input type="number" :value="values[1]" @input="state.refine([values[0], $event.target.value])" :step="step"
        :min="range.min" :max="range.max" />
      <button @click="state.refine([range.min, range.max])">Reset</button>
    </div>
  </div>
</template>

<script>
import { connectRange } from 'instantsearch.js/es/connectors';
import { createPanelConsumerMixin } from '../mixins/panel';
import { createWidgetMixin, createSuitMixin } from 'vue-instantsearch';
import 'vue-slider-component/theme/default.css';

import VueSlider from 'vue-slider-component';
export default {
  name: 'Ais-range-slider',
  components: {
    VueSlider,
  },
  mixins: [
    createSuitMixin({ name: 'RangeSlider' }),
    createWidgetMixin(
      { connector: connectRange },
      { $$widgetType: 'ais.rangeSlider' }
    ),
    createPanelConsumerMixin(),
  ],
  props: {
    attribute: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
      default: undefined,
    },
    min: {
      type: Number,
      required: false,
      default: undefined,
    },
    max: {
      type: Number,
      required: false,
      default: undefined,
    },
    precision: {
      type: Number,
      required: false,
      default: 0,
    },
    formatValue: {
      type: Function,
      required: false,
      default: (value) => value,
    },
    stepThresholds: {
      type: Object,
      required: false,
      default: () => ({
        0: 1,
        50: 5,
        100: 10,
        250: 25,
        500: 50,
        1000: 100,
        2500: 250,
        5000: 500,
        10000: 1000,
        25000: 2500,
        50000: 5000,
        100000: 10000,
      }),
    },
    showSuggestions: {
      type: Number,
      required: false,
      default: 6,
    },
    suggestions: {
      type: Array,
      required: false
    },
    histogramBuckets: {
      type: Number,
      required: false,
      default: 0,
    },
    logScale: {
      type: Number,
      required: false,
      default: 0,
    },
    input: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      totalCount: 0,
      maxCount: 0,
    };
  },
  computed: {
    widgetParams () {
      return {
        attribute: this.attribute,
        min: this.min,
        max: this.max,
        precision: this.precision,
      };
    },
    range () {
      const { min: rangeMin, max: rangeMax } = this.state.range;

      return {
        min: this.min ?? rangeMin,
        max: this.max ?? rangeMax,
      };
    },
    step () {
      return 1 / Math.pow(10, this.precision);
    },
    steps () {
      const { range, stepThresholds } = this;
      const thresholds = Object.keys(stepThresholds).map(Number);
      const stepSizes = Object.values(stepThresholds);

      const steps = [];
      let stepIndex = thresholds.findIndex((threshold) => range.min >= threshold)
      if (stepIndex === -1) stepIndex = thresholds.length - 1;

      for (let i = range.min; i < range.max + stepSizes[stepIndex]; i += stepSizes[stepIndex]) {
        steps.push(i);
        if (stepIndex < thresholds.length && i >= thresholds[stepIndex + 1]) stepIndex++;
      }

      return steps;
    },
    quickSuggestions () {
      const { range, suggestions, showSuggestions } = this;

      return suggestions
        .filter(({ value }) => value[0] >= range.min && value[1] <= range.max)
        .slice(0, showSuggestions);
    },
    values () {
      const { steps } = this;
      const [minValue, maxValue] = this.state.start;
      const { min: minRange, max: maxRange } = this.state.range;

      const min = Math.max(minValue ?? minRange, minRange);
      const max = Math.min(maxValue ?? maxRange, maxRange);

      const minStep = steps.find((step) => step >= min) ?? steps[0];
      const maxStep = steps.find((step) => step >= max) ?? steps[steps.length - 1];

      return [minStep, maxStep];
    },
    histogramThresholds () {
      const { steps, histogramBuckets } = this;

      if (histogramBuckets === 0) return [];

      const interval = Math.floor(steps.length / histogramBuckets);
      let overflow = steps.length % histogramBuckets;

      const thresholds = [];
      for (let i = 0; i < steps.length - 1 && (interval > 0 || overflow > 0); i += interval + (overflow > 0 ? 1 : 0)) {
        thresholds.push(steps[i]);
        overflow--;
      }

      return thresholds;
    }
  },
  methods: {
    transformToHistogram (items) {
      const { histogramThresholds } = this;

      items = [...items].sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
      const min = parseFloat(items[0].value);
      const max = parseFloat(items[items.length - 1].value);

      this.totalCount = items.reduce((sum, { count }) => sum + count, 0);
      this.maxCount = 0;

      if (histogramThresholds.length === 0) {
        const label = `${min} - ${max} (${items.reduce((sum, { count }) => sum + count, 0)})`;
        this.maxCount = this.totalCount;

        return [{
          label,
          count: this.totalCount,
          highlighted: label,
          value: "0",
          isRefined: false,
        }];
      }

      return histogramThresholds.map((threshold, i) => {
        const nextThreshold = histogramThresholds[i + 1] ?? Infinity;
        const count = items.reduce((sum, { value, count }) => {
          return sum + (+value >= threshold && +value < nextThreshold ? count : 0);
        }, 0);

        const label = nextThreshold === Infinity
          ? `> ${threshold} (${count})`
          : `${threshold} - ${nextThreshold === Infinity ? max : nextThreshold} (${count})`;

        if (count > this.maxCount) this.maxCount = count;

        return {
          label,
          count,
          highlighted: label,
          value: [threshold, nextThreshold === Infinity ? undefined : nextThreshold].join(':'),
          isRefined: false,
        }
      })
    },
    scaleValue (value, max = 1) {
      const { logScale } = this;

      const fraction = value / max;
      return logScale ? Math.log(1 + logScale * fraction) / Math.log(1 + logScale) : fraction;
    },
  },
};
</script>

<style>
.ais-RangeInput__suggestionList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--aa-spacing-half);
  margin-bottom: var(--aa-spacing);
}

.ais-RangeInput__suggestion {
  background: #f5f5fa;
  border: 1px solid #dee2e6;
  border-radius: 999px;
  color: #212529;
  cursor: pointer;
  display: flex;
  gap: var(--aa-spacing-half);
  font-size: 14px;
  padding: var(--aa-spacing-half) var(--aa-spacing);
  justify-content: center;
}

.ais-RefinementList__histogram .ais-RefinementList-list {
  display: flex;
  align-items: end;
  justify-content: stretch;
  height: 100px;
  padding: 0 7px;
}

.ais-RefinementList__histogram .ais-RefinementList-item {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.ais-RefinementList__bucket {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 0;
  position: relative;
}

.ais-RefinementList__bucketDisplay {
  width: 100%;
  background-color: #3498db;
  position: absolute;
  bottom: 0;
}

.ais-RefinementList__bucket:hover {
  /* grey */
  background-color: #ecf0f1;
}

.ais-RefinementList__bucket:hover .ais-RefinementList__bucketDisplay {
  background-color: #2980b9;
}

.ais-RangeInput__values {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 5px;
}

.ais-RangeInput__values input {
  flex-grow: 1;
}
</style>
