<template>
  <div>
    <div class="container">
      <ais-instant-search :search-client="searchClient" :routing="routing" index-name="item_group">
        <ais-configure :hits-per-page.camel="24" :filters="itemGroupFilters" />
        <div class="search-panel">
          <div class="search-panel__menuWrapper" :class="showMenu ? 'search-panel__menuWrapperShow' : ''"
            @click.self="showMenu = false">
            <div class="search-panel__menu">
              <div class="search-panel__filters">
                <ais-dynamic-widgets :max-values-per-facet="500" :facets="[]">
                  <ais-panel>
                    <template #header="{ hasRefinements }">
                      <h2>Kategorie <span v-if="!hasRefinements">(keine Ergebnisse)</span></h2>
                    </template>
                    <ais-hierarchical-menu :attributes="[
                      'category.lvl1',
                      'category.lvl2',
                      'category.lvl3',
                      'category.lvl4',
                      'category.lvl5',
                      'category.lvl6'
                    ]" :sort-by="['isRefined', 'count:desc']" :limit="25" :show-more-limit="500" show-more
                      :transform-items="sortItemsAlphanumeric" />
                  </ais-panel>
                </ais-dynamic-widgets>
              </div>
            </div>
          </div>

          <div class="search-panel__results">
            <div class="searchbox">
              <autocomplete-search-box />
            </div>
            <ais-breadcrumb :attributes="[
              'category.lvl1',
              'category.lvl2',
              'category.lvl3',
              'category.lvl4',
              'category.lvl5',
              'category.lvl6'
            ]" :transformItems="(items, { results }) => {
              const systemManufacturers = results._state.disjunctiveFacetsRefinements.system_manufacturer;
              return systemManufacturers?.length === 1
                ? [{ label: systemManufacturers[0], value: null }, ...items]
                : items;
            }" />
            <ais-stats />
            <ais-sort-by :items="[
              { value: 'item_group/sort/_text_match(buckets: 10):desc,sales_rank:desc', label: 'Relevanz' },
              { value: 'item_group/sort/sales_rank:desc,_text_match:desc', label: 'Top Seller' },
              { value: 'item_group/sort/min_price:asc,_text_match:desc', label: 'Preis aufsteigend' },
              { value: 'item_group/sort/min_price:desc,_text_match:desc', label: 'Preis absteigend' },
              { value: 'item_group/sort/name:asc,_text_match:desc', label: 'Name A-Z' },
              { value: 'item_group/sort/name:desc,_text_match:desc', label: 'Name Z-A' },
            ]" />
            <ais-current-refinements />
            <ais-clear-refinements />
            <ais-dynamic-widgets :class-names="{ 'ais-DynamicWidgets': 'ais-DynamicWidgets__verticalList' }">
              <ais-refinement-list attribute="availability"
                :transform-items="(items) => items.filter(({ value }) => value === 'Sofort lieferbar')" />
              <ais-toggle-refinement attribute="is_sale" label="Sale %" />
              <ais-dropdown title="Preis" min-width="300px">
                <ais-range-slider attribute="min_price" :limit="500" :formatValue="(value) => `${value.toFixed(2)} €`"
                  :suggestions="[
                    { label: 'unter 5€', value: [0, 5] },
                    { label: 'unter 10€', value: [0, 10] },
                    { label: '10-20€', value: [10, 20] },
                    { label: '20-50€', value: [20, 50] },
                    { label: '50-100€', value: [50, 100] },
                    { label: '100-200€', value: [100, 200] },
                    { label: '200-500€', value: [200, 500] },
                    { label: '500-1000€', value: [500, 1000] },
                    { label: 'über 1000€', value: [1000, 100000] },
                  ]" :histogram-buckets="20" :log-scale="10" input />
              </ais-dropdown>
              <ais-dropdown title="Verfügbarkeit">
                <ais-refinement-list attribute="availability"
                  :transform-items="(items) => sortItemsCustom(items, ['Sofort lieferbar', 'Lieferzeit 4-7 Tage', 'Lieferzeit 1-2 Wochen', 'Vorbestellung', 'Ausverkauft'])" />
              </ais-dropdown>
              <ais-dropdown title="Unterkategorie">
                <ais-refinement-list attribute="type" :limit="7" :show-more-limit="500" show-more
                  :transform-items="(items) => sortItemsCustom(items, ['Spiele', 'Konsolen', 'Zubehör'])" />
              </ais-dropdown>
              <ais-dropdown title="Genre">
                <ais-refinement-list attribute="genre" :limit="7" :show-more-limit="500" show-more />
              </ais-dropdown>
              <ais-dropdown title="Hersteller">
                <ais-refinement-list attribute="system_manufacturer" :limit="7" searchable show-more
                  :show-more-limit="500" />
              </ais-dropdown>
              <ais-dropdown title="Version">
                <ais-refinement-list attribute="versions" :limit="7" searchable show-more :show-more-limit="500"
                  :transform-items="(items) => sortItemsCustom(items, ['DE Version', 'UK Version', 'JAP version', 'US Version'])">
                  <template #item="{ item, refine }">
                    <label class="ais-RefinementList-label">
                      <input class="ais-RefinementList-checkbox" type="checkbox" :value="item.value"
                        :checked="item.isRefined" @change="refine(item.value)" />
                      <img class="ais-RefinementList-icon" :src="getItemCountryFlagSrc(item)">
                      <ais-highlight attribute="item" :hit="item" />
                      <span class="ais-RefinementList-count">{{ item.count }}</span>
                    </label>
                  </template>
                </ais-refinement-list>
              </ais-dropdown>
              <ais-dropdown title="Farbe" min-width="300px">
                <ais-refinement-list attribute="color_groups" :limit="7" show-more :show-more-limit="500">
                  <template v-slot="{ items, refine, canToggleShowMore, isShowingMore, toggleShowMore }">
                    <color-refinement-list :items="items" :refine="refine" :can-toggle-show-more="canToggleShowMore"
                      :is-showing-more="isShowingMore" :toggle-show-more="toggleShowMore" />
                  </template>
                </ais-refinement-list>
              </ais-dropdown>
              <ais-dropdown title="Verpackung">
                <ais-refinement-list attribute="packaging" :limit="7" :show-more-limit="500" show-more
                  :transform-items="(items) => sortItemsCustom(items, ['mit OVP', 'Steelbook', 'Big Box', 'Sealed', 'Jewelcase'])" />
              </ais-dropdown>
              <ais-dropdown title="Besonderheiten">
                <ais-toggle-refinement attribute="is_graded" label="Graded" />
                <ais-toggle-refinement attribute="is_ips" label="mit IPS Display Mod" />
                <ais-toggle-refinement attribute="is_import" label="Import Version" />
              </ais-dropdown>
              <ais-dropdown title="Zustand">
                <ais-refinement-list attribute="conditions" :limit="7" :show-more-limit="500" show-more
                  :transform-items="(items) => sortItemsCustom(items, ['NEU & OVP', 'NEU', 'NEUWERTIG', 'sehr guter Zustand', ' gebraucht', 'mit neuem Gehäuse', 'starke Gebrauchsspuren'])" />
              </ais-dropdown>
              <ais-dropdown title="Altersfreigabe">
                <ais-refinement-list attribute="usk" :limit="7" :show-more-limit="500" show-more
                  :transform-items="sortItemsAlphanumeric" />
              </ais-dropdown>
              <ais-dropdown title="Sprache">
                <ais-refinement-list attribute="languages" :limit="7" :show-more-limit="500" show-more searchable
                  :transform-items="(items) => sortItemsCustom(items, ['Deutsch', 'Englisch'], sortItemsAlphanumeric)" />
              </ais-dropdown>
            </ais-dynamic-widgets>
            <ais-infinite-hits show-previous>
              <template #item="{ item }">
                <img :src="item.cover_thumbnail" alt="cover" />
                <ais-highlight :hit="item" attribute="name" />
                <div style="display: flex; gap: 4px; flex-wrap: wrap; margin: .5rem 0;">
                  <div v-for="variation in item.item_variation" :key="variation.id"
                    style="background-color: #ddd; padding: 0px 8px; border-radius: 5px; font-size: 0.5rem;">
                    {{ variation.condition[0] }}
                  </div>
                </div>
                <div class="hit-price">ab {{ item.min_price }} €</div>
              </template>
            </ais-infinite-hits>
            <div class="pagination">
              <ais-pagination />
            </div>
          </div>
        </div>
        <button class="search-panel__menuButton" @click="showMenu = !showMenu">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="12" cy="12" r="3"></circle>
            <path
              d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
            </path>
          </svg>
          <div>Filter {{ showMenu ? 'schließen' : 'öffnen' }}</div>
        </button>
      </ais-instant-search>
    </div>
  </div>
</template>

<script>
import '@algolia/autocomplete-theme-classic';
import { history } from 'instantsearch.js/es/lib/routers';
import { singleIndex } from 'instantsearch.js/es/lib/stateMappings';
import AutocompleteSearchBox from "./components/AutocompleteSearchBox.vue";
import { searchClient } from "./searchClient";
import { INSTANT_SEARCH_INDEX_NAME } from './constants';
import ColorRefinementList from './components/ColorRefinementList.vue';
import AisDropdown from './components/AisDropdown.vue';
import AisRangeSlider from './components/AisRangeSlider.vue';

export default {
  components: {
    AutocompleteSearchBox,
    ColorRefinementList,
    AisDropdown,
    AisRangeSlider,
  },
  data () {
    return {
      searchClient,
      showMenu: false,
      showMinPriceSuggestions: 6,
      routing: {
        router: history(),
        stateMapping: singleIndex(INSTANT_SEARCH_INDEX_NAME),
      },
      itemGroupFilters: '$item_variation(id:*)',
      debouncedCallbacks: {},
    };
  },
  methods: {
    toValue (value, range) {
      return [
        typeof value.min === "number" ? value.min : range.min,
        typeof value.max === "number" ? value.max : range.max,
      ];
    },
    getItemCountryFlagSrc (item) {
      const country = item.label.match(/([^\s]+) (?:Version|Import)/)?.[1];
      return country
        ? `https://cdn02.plentymarkets.com/xp4oxtd91bsc/plugin/484/d2gpmthemekonsolenkost/images/flags/${country.toLowerCase()}.svg`
        : `https://cdn02.plentymarkets.com/xp4oxtd91bsc/plugin/484/d2gpmthemekonsolenkost/images/flags/de.svg`;
    },
    updateUiState ({ uiState, setUiState }) {
      const itemVariationRefinement = uiState.item_variation?.refinementList ?? {};
      const itemVariationFilter = this.mapToAlgoliaFilters(itemVariationRefinement);

      this.itemGroupFilters = `$item_variation(${itemVariationFilter || 'id:*'})`;

      setUiState(uiState);
    },
    mapToAlgoliaFilters (tagsByFacet, operator = '&&') {
      return Object.keys(tagsByFacet)
        .map((facet) => {
          const values = Array.isArray(tagsByFacet[facet]) ? tagsByFacet[facet] : [tagsByFacet[facet]];
          return `(${values.map((value) => `${facet}:"${value}"`).join(' || ')})`;
        })
        .join(` ${operator} `);
    },
    sortItemsAlphanumeric (items, locale = 'de', collatorOptions = { sensitivity: 'base', numeric: true }) {
      return [...items].sort((a, b) => a.label.localeCompare(b.label, locale, collatorOptions));
    },
    sortItemsByCount (items) {
      return [...items].sort((a, b) => b.count - a.count);
    },
    sortItemsCustom (items, customOrder, defaultSortFn) {
      items = (defaultSortFn || this.sortItemsByCount)([...items]);

      return items.sort((a, b) => {
        const aIndex = customOrder.indexOf(a.label);
        const bIndex = customOrder.indexOf(b.label);

        if (aIndex === -1 && bIndex === -1) return 0;
        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;

        return aIndex - bIndex;
      });
    },
  },
};
</script>

<style>
body,
h1 {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

em {
  background: cyan;
  font-style: normal;
}

.header {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 0.5rem 1rem;
  background-image: linear-gradient(to right, #4dba87, #2f9088);
  color: #fff;
  margin-bottom: 1rem;
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.header-title::after {
  content: ' ▸ ';
  padding: 0 0.5rem;
}

.header-subtitle {
  font-size: 1.2rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.search-panel {
  display: flex;
  gap: 1rem;
}

.search-panel__filters {
  min-width: 300px;
}

.search-panel__results {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.ais-InfiniteHits-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.ais-InfiniteHits-item {
  flex-direction: column;
  padding: .5rem;
  justify-content: space-between;
}

.search-panel__menuButton {
  display: none;
}

.ais-ToggleRefinement-label {
  flex-direction: row;
}

.ais-DynamicWidgets__verticalList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--aa-spacing);
}

.ais-DynamicWidgets__verticalList .ais-DynamicWidgets-widget:last-child {
  margin-right: auto;
}

.ais-RefinementList-icon {
  height: 1em;
  margin-right: 0.5em;
}

@media (max-width: 600px) {
  .search-panel__menuWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow: hidden;
    display: none;
  }

  .search-panel__menuWrapperShow {
    display: block;
  }

  .search-panel__menuButton {
    position: fixed;
    z-index: 100;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #ffd700;
    border: none;
    border-radius: 25px;
    padding: 12px 24px;
    font-size: 1.2rem;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  .search-panel__menuButton:hover {
    background-color: #ffed4a;
  }

  .search-panel__menu {
    position: absolute;
    box-sizing: border-box;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 340px;
    background: #fff;
    border-right: 1px solid #ddd;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .5);
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
