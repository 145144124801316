import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import {
  INSTANT_SEARCH_API_KEY,
  INSTANT_SEARCH_APP_ID,
  INSTANT_SEARCH_HOST,
  INSTANT_SEARCH_PATH,
  INSTANT_SEARCH_PORT,
  INSTANT_SEARCH_PROTOCOL,
} from "./constants";

export const searchAdapter = new TypesenseInstantsearchAdapter({
  server: {
    apiKey: INSTANT_SEARCH_API_KEY, // Be sure to use the search-only-api-key
    nodes: [
      {
        host: INSTANT_SEARCH_HOST,
        port: INSTANT_SEARCH_PORT,
        protocol: INSTANT_SEARCH_PROTOCOL,
        path: INSTANT_SEARCH_PATH,
      }
    ]
  },
  // The following parameters are directly passed to Typesense's search API endpoint.
  //  So you can pass any parameters supported by the search endpoint below.
  //  query_by is required.
  additionalSearchParameters: {
    query_by: "*", // Search on all fields by default
  },
  collectionSpecificSearchParameters: {
    item_group: {
      query_by: "name,system,type,genre,category.lvl1,category.lvl2,category.lvl3,category.lvl4,category.lvl5,category.lvl6,system_manufacturer,manufacturer,eans",
      sort_by: "_text_match(buckets: 10):desc,sales_rank:desc",
      filter_by: "$item_variation(id:*)", //  Must be set to include variations
      include_fields: "$item_variation(*, strategy: nest_array)",
    },
  },
  facetableFieldsWithSpecialCharacters: [
    "category.lvl1",
    "category.lvl2",
    "category.lvl3",
    "category.lvl4",
    "category.lvl5",
    "category.lvl6",
    "genre",
    "system",
  ],
  renderingContent: {
    facetOrdering: {
      facets: {
        order: [
          "is_sale",
          "min_price",
          "conditions",
          "availability",
          "system_manufacturer",
          "type",
          "genre",
          "color_groups",
          "is_graded",
          "is_ips",
          "is_import",
          "packaging",
          "versions",
          "languages",
          "usk",
          "category.lvl1",
          "category.lvl2",
          "category.lvl3",
          "category.lvl4",
          "category.lvl5",
          "category.lvl6",
        ]
      }
    },
  }
});

export const searchClient = searchAdapter.searchClient;
export const typesenseClient = searchAdapter.typesenseClient;

searchClient.transporter = {
  headers: {
    "x-algolia-application-id": INSTANT_SEARCH_APP_ID,
    "x-algolia-api-key": INSTANT_SEARCH_API_KEY,
  },
}
