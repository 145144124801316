<template>
  <div :class="`ais-ColorRefinementList ais-ColorRefinementList-layout${layout} ais-ColorRefinementList-shape${shape}`">
    <div class="ais-ColorRefinementList-items" role="group" :aria-label="`${items.length} colors`">
      <button type="button" v-for="item in items" :key="item.label" class="ais-ColorRefinementList-item"
        :class="item.isRefined ? 'ais-ColorRefinementList-itemRefined' : ''" role="menuitemcheckbox"
        :aria-checked="item.isRefined" :aria-label="item.label" @click.prevent="refine(item.value)">
        <div class="ais-ColorRefinementList-color" :class="`color--${item.value}`" :style="getColorStyle(item.value)">
          <svg class="ais-ColorRefinementList-refinedIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
            fill="currentColor" :style="{
              '--contrast-color': 'black',
            }">
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
          </svg>
        </div>
        <div class="ais-ColorRefinementList-label">{{ item.label }}</div>
        <div class="ais-ColorRefinementList-count">
          {{ item.count.toLocaleString() }}
        </div>
      </button>
    </div>
    <button v-if="canToggleShowMore" type="button" class="ais-RefinementList-showMore" @click="toggleShowMore">
      {{ isShowingMore ? 'Weniger anzeigen' : 'Mehr anzeigen' }}
    </button>
  </div>

</template>

<script>
export default {
  props: {
    items: { type: Array, required: true },
    refine: { type: Function, required: true },
    layout: { type: String, default: 'Grid' },
    shape: { type: String, default: 'Circle' },
    pinRefined: { type: Boolean, default: false },
    canToggleShowMore: { type: Boolean, default: false },
    isShowingMore: { type: Boolean, default: false },
    toggleShowMore: { type: Function, default: () => { } },
    colors: { type: Object, default: () => ({}) },
  },
  data: () => ({
    defaultColors: {
      beige: "#f5f5dc",      // beige
      schwarz: "#000000",    // black
      blau: "#0000ff",       // blue
      bronze: "#cd7f32",     // bronze
      braun: "#a52a2a",      // brown
      klar: "#d0d0d0",       // clear
      gold: "#ffd700",       // gold
      grau: "#808080",       // gray
      grün: "#008000",      // green
      orange: "#ffa500",     // orange
      rosa: "#ffc0cb",       // pink
      lila: "#800080",       // purple
      rot: "#ff0000",        // red
      silber: "#c0c0c0",     // silver
      türkis: "#40e0d0",    // turquoise
      weiss: "#ffffff",      // white
      gelb: "#ffff00"        // yellow
    },
  }),
  methods: {
    getColorStyle (colorName) {
      const colors = { ...this.defaultColors, ...this.colors };
      const color = colors[colorName];

      return color && {
        backgroundColor: color,
      };
    },
    getContrastColor (rgb) {
      const [r, g, b] = rgb;
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? 'black' : 'white';
    },
  },
}
</script>

<style scoped>
/* ========================= ColorRefinementList Widget */
/* ---------- CSS variables */
.ais-ColorRefinementList {
  --transition-duration: 0.2s;
  --items-column-width: 3rem;
  --items-gap: 1rem;
  --refined-icon-size: 1.1rem;
  --color-size: 1.75rem;
}

/* ---------- List layout */
.ais-ColorRefinementList-layoutList .ais-ColorRefinementList-items {
  grid-template-columns: 1fr;
}

.ais-ColorRefinementList-layoutList .ais-ColorRefinementList-item {
  flex-basis: 100%;
  flex-direction: row;
  max-width: 100%;
}

.ais-ColorRefinementList-layoutList .ais-ColorRefinementList-color {
  margin-right: 0.8rem;
}

.ais-ColorRefinementList-layoutList .ais-ColorRefinementList-label {
  margin-top: 0;
}

.ais-ColorRefinementList-layoutList .ais-ColorRefinementList-count {
  display: block;
  margin-left: auto;
}

/* ---------- Items */
.ais-ColorRefinementList-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--items-column-width), 1fr));
  gap: var(--items-gap);
}

.ais-ColorRefinementList-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  color: black;

  /* Reset button styles */
  background: transparent;
  border: 0px;
  padding: 0px;
  cursor: pointer;
}

/* ---------- Color */
.ais-ColorRefinementList-color {
  width: var(--color-size);
  height: var(--color-size);
  border-radius: 9999px;
  transition: var(--transition-duration) transform ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
}

/* Hover/active states */
.ais-ColorRefinementList-item {
  &:hover .ais-ColorRefinementList-color {
    transform: scale(1.1);
  }

  &:active .ais-ColorRefinementList-color {
    transform: scale(0.9);
  }
}

/* Specific case: Add border to color circle to distinguish it from the background */
.ais-ColorRefinementList-color.color--weiß,
.ais-ColorRefinementList-color.color--white {
  border: 1px solid #d1d1d1;
}

.ais-ColorRefinementList-color.color--klar,
.ais-ColorRefinementList-color.color--transparent,
.ais-ColorRefinementList-color.color--gold,
.ais-ColorRefinementList-color.color--bronze,
.ais-ColorRefinementList-color.color--silver,
.ais-ColorRefinementList-color.color--silber {
  background: linear-gradient(45deg, transparent 30%, #ffffff88 40%, #ffffff88 50%, #fff 55%, #ffffffcc 60%, #ffffffcc 65%, transparent 70%);
}

.ais-ColorRefinementList-color.color--multicolor,
.ais-ColorRefinementList-color.color--mehrfarbig {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to right, #06cd1d 50%, transparent 0%),
    linear-gradient(60deg, #7e00ff 50%, transparent 0%),
    linear-gradient(30deg, #ff00bd 50%, transparent 0%),
    linear-gradient(0deg, #ff0000 50%, transparent 0%),
    linear-gradient(-30deg, #ff4700 50%, transparent 0%),
    linear-gradient(-60deg, #ffa500 50%, transparent 0%),
    linear-gradient(-90deg, #ffff00 50%, transparent 0%);
}

.ais-ColorRefinementList-color.color--multicolor::before,
.ais-ColorRefinementList-color.color--mehrfarbig::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 50%;
  right: 50%;
  background: linear-gradient(210deg, transparent 64%, #09ddbf 0%),
    linear-gradient(240deg, transparent 37%, #34ff00 0%);
}

.ais-ColorRefinementList-color.color--multi::after,
.ais-ColorRefinementList-color.color--mehrfarbig::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  bottom: 0;
  right: 50%;
  background: linear-gradient(150deg, #00acff 37%, transparent 0%),
    linear-gradient(120deg, #0075ff 63%, #1200ff 0%);
}

/* Shape modifiers */
.ais-ColorRefinementList-shapeSquare .ais-ColorRefinementList-color {
  border-radius: 0;
}

/* ---------- Label */
.ais-ColorRefinementList-label {
  margin-top: 0.3rem;
  transition: 0.3s color ease-out;
  padding: 0 0.3rem;
}

.ais-ColorRefinementList-item:hover .ais-ColorRefinementList-label {
  color: #848484;
}

/* ---------- Count */
.ais-ColorRefinementList-count {
  display: none;
}

/* ---------- RefinedIcon */
.ais-ColorRefinementList-refinedIcon {
  position: relative;
  width: var(--refined-icon-size);
  height: var(--refined-icon-size);
  color: var(--contrast-color);
  transform: scale(0);
  visibility: hidden;
  opacity: 0;
  transition: transform var(--transition-duration) ease-out 0s, opacity var(--transition-duration) linear 0s, visibility 0s linear var(--transition-duration);
}

.ais-ColorRefinementList-item.ais-ColorRefinementList-itemRefined .ais-ColorRefinementList-refinedIcon {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
  transition: transform var(--transition-duration) ease-out 0s, opacity var(--transition-duration) linear 0s, visibility 0s linear 0s;
}
</style>
